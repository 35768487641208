import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledMenu = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.backgroundcolor};
  padding: 4rem 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: ${({ open }) => (open ? '1' : '0')};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.desktop}) {
    display: none;
  }

  div {
    border-top: 2px solid ${({ theme }) => theme.primary};
    margin-bottom: 3rem;

    a {
      display: flex;
      justify-content: flex-start;
      text-decoration: none;
      text-align: left;
      color: ${({ theme }) => theme.subprimary};
      padding: 1rem 0rem 1rem 1.7rem;
      border-bottom: 2px solid ${({ theme }) => theme.primary};

      &:hover {
        background-color: ${({ theme }) => theme.primary};
      }
    }
  }

  aside {
    display: flex;
    justify-content: space-around;
    height: 100%;

    span {
      font-size: 1.5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      min-height: 56.5px;

      a {
        border-top: 2px solid ${({ theme }) => theme.primary};
        border-bottom: 2px solid ${({ theme }) => theme.primary};
        border-right: 2px solid ${({ theme }) => theme.primary};
        font-size: 1.5rem;
        cursor: pointer;
        color: ${({ theme }) => theme.subprimary};
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 56.5px;
        height: 100%;

        &:hover {
          color: ${({ theme }) => theme.highlightcolor};
          background-color: ${({ theme }) => theme.primary};
        }
      }
    }
  }
`

const Menu = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <div>
        <Link to="/">Home</Link>
        <Link to="/history/">History</Link>
        <Link to="/sponsorship/">Sponsorship</Link>
        <Link to="/registration/">Registration</Link>
        <Link to="/directions/">Directions</Link>
        <Link to="/contact/">Contact</Link>
      </div>
    </StyledMenu>
  )
}

export default Menu
