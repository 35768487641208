import React from 'react'
import { ThemeProvider } from 'styled-components'

const ThemeToggleContext = React.createContext()

export const useTheme = () => React.useContext(ThemeToggleContext)

const LIGHT_MODE = {
  // Colours
  highlightcolor: '#80d4a7',
  primary: '#f7f8fc',
  subprimary: '#292929',
  backgroundcolor: '#ffffff',
  // Breakpoints
  desktop: '1400px',
  tablet: '700px',
  mobile: '460px',
}

const DARK_MODE = {
  // Colours
  highlightcolor: '#66abf2',
  primary: '#090909',
  subprimary: '#f5f5f5',
  backgroundcolor: '#000000',
  // Breakpoints
  desktop: '1234px',
  tablet: '700px',
  mobile: '460px',
}

export const MyThemeProvider = ({ children }) => {
  const [themeState, setThemeState] = React.useState({
    mode: LIGHT_MODE,
  })

  const mode = themeState.mode

  const toggle = () => {
    if (mode === LIGHT_MODE) {
      const mode = themeState.mode === LIGHT_MODE ? DARK_MODE : LIGHT_MODE
      setThemeState({ mode: mode })
    }

    if (mode === DARK_MODE) {
      const mode = themeState.mode === DARK_MODE ? LIGHT_MODE : DARK_MODE
      setThemeState({ mode: mode })
    }
  }

  return (
    <ThemeToggleContext.Provider value={{ toggle: toggle }}>
      <ThemeProvider theme={themeState.mode}>{children}</ThemeProvider>
    </ThemeToggleContext.Provider>
  )
}

export default ThemeProvider
