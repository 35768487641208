import React from 'react'
import SiteHeader from '../components/siteHeader'
import SiteFooter from '../components/siteFooter'
import { MyThemeProvider } from '../themeContext'
import { GlobalCSS } from '../globalStyles'
import styled from 'styled-components'

const StyledMain = styled.main`
  margin-left: auto;
  margin-right: auto;
`

const SiteLayout = ({ children }) => (
  <MyThemeProvider>
    <GlobalCSS />
    <SiteHeader />
    <StyledMain>{children}</StyledMain>
    <SiteFooter />
  </MyThemeProvider>
)

export default SiteLayout
