import React, { useState } from 'react'
import { Link } from 'gatsby'
import Burger from './burger'
import Menu from './menu'
import styled from 'styled-components'
import LogoLight from './logoLight'

const StyledHeader = styled.header`
  top: 0;
  position: absolute;
  z-index: 9;
  padding: 0 4vw;
  width: 88%;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  max-width: 1920px;

  @media (max-width: 600px) {
    padding: 0;
    .navHeader {
      max-height: 100px;
    }
  }

  .navHeader {
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navigation {
      display: flex;
      justify-content: space-between;
      flex: 2;
      z-index: 2;
      margin-left: 20rem;

      @media (max-width: 1520px) {
        margin-left: 10rem;
      }

      a {
        text-decoration: none;
        color: ${({ theme }) => theme.backgroundcolor};
      }
    }

    .logo {
      flex: 1;
      text-align: center;
      max-width: 16rem;

      z-index: 10;
      margin-right: 12rem;
      display: flex;

      img {
        margin: 0;
      }

      @media (max-width: 500px) {
        min-width: 8rem;
        margin-right: 0;
      }
    }

    @media (max-width: ${({ theme }) => theme.desktop}) {
      .navigation {
        display: none;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.desktop}) {
    .navHeader {
      justify-content: space-between;
      align-items: center;
    }
  }
`

const SiteHeader = () => {
  const [open, setOpen] = useState(false)

  return (
    <StyledHeader>
      <div className="navHeader">
        <div className="logo">
          <Link to="/">
            <LogoLight />
          </Link>
        </div>
        <div className="navigation">
          <Link to="/" activeStyle={{ textDecoration: 'underline' }}>
            Home
          </Link>
          <Link to="/history/" activeStyle={{ textDecoration: 'underline' }}>
            History
          </Link>
          <Link to="/sponsorship/" activeStyle={{ textDecoration: 'underline' }}>
            Sponsorship
          </Link>
          <Link to="/registration/" activeStyle={{ textDecoration: 'underline' }}>
            Registration
          </Link>
          <Link to="/directions/" activeStyle={{ textDecoration: 'underline' }}>
            Directions
          </Link>
          <Link to="/contact/" activeStyle={{ textDecoration: 'underline' }}>
            Contact
          </Link>
        </div>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
    </StyledHeader>
  )
}

export default SiteHeader
