import React from 'react'
import styled from 'styled-components'
import LogoLight from './logoLight'

const StyledFooter = styled.footer`
  display: flex;
  padding-left: 8vw;
  padding-right: 8vw;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: ${({ theme }) => theme.subprimary};
  color: ${({ theme }) => theme.backgroundcolor};
  flex-direction: column;

  .logo {
    display: flex;
  }

  @media (max-width: 600px) {
    .logo {
      justify-content: center;
    }

    .social {
      display: flex;
      justify-content: center;
    }
  }

  .social {
    padding: 0 0 2rem 0;

    span {
      a {
        cursor: pointer;
        padding: 0 1.3rem;
        font-size: 2rem;
        color: ${({ theme }) => theme.primary};
        height: 100%;
        width: 100%;

        &:hover {
          color: ${({ theme }) => theme.highlightcolor};
        }
      }
    }
  }

  .copywrite {
    text-align: center;
  }
`

const SiteFooter = () => {
  return (
    <StyledFooter>
      <div className="logo">
        <a href="https://circlek.com" target="_blank" rel="noopener noreferrer">
          <LogoLight />
        </a>
      </div>
      {/* <div className="social">
        <span>
          <a
            href="https://twitter.com/circlekstores"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
        </span>
        <span>
          <a
            href="https://www.facebook.com/CircleKStores/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
        </span>
        <span>
          <a
            href="https://www.instagram.com/circlekstores/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </span>
        <span>
          <a
            href="https://www.youtube.com/c/CircleKoffical"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
        </span>
      </div> */}
      <div className="copywrite">
        © Copyright 2023 Circle K's Golf. All rights reserved. Web design and development by SIX12 Creative.
      </div>
    </StyledFooter>
  )
}

export default SiteFooter
