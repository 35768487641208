import React from 'react'
import styled from 'styled-components'

const StyledLogo = styled.svg`
  .st0 {
    fill: none;
  }
  .st1 {
    fill: #ffffff;
  }
  .st2 {
    fill: #e1251b;
  }
  .st3 {
    fill: #f18a00;
  }
`

const LogoLight = () => {
  return (
    <StyledLogo
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1006.2 576"
      height="80px"
    >
      <rect class="st0" width="1006" height="576" />
      <rect x="143.8" y="144" class="st1" width="718.3" height="287.9" />
      <rect x="147.9" y="148.1" class="st2" width="710.2" height="233.1" />
      <rect x="147.9" y="392.9" class="st3" width="710.2" height="35" />
      <path
        class="st1"
        d="M297.1,230h22.3v86.8h-22.3V230 M358.4,274.6c8.8,0,15.1-4.4,15.1-13.4c0-8.8-5.9-12.8-15-12.8h-6.4v26.2H358.4
	z M513.1,297.1h30v19.8h-52.8V230H513L513.1,297.1L513.1,297.1z M550.4,230.1h57.1v19.7h-34.7v14.3H606v18.2h-33.2V297h34.8v19.8
	h-57.2L550.4,230.1L550.4,230.1z M743.7,271.8l30.8-41.7h-31.6l-28.5,40v-40h-27.7v86.8h27.7v-40l49.2,63.5
	c-12.8,7.8-27.5,11.9-42.4,11.8c-44.7,0-78.7-34-78.7-78.7s34-78.6,78.7-78.6s78.7,34,78.7,78.6c0.1,17.6-5.5,34.7-16.1,48.7
	L743.7,271.8z M395.7,258.8c0,12.7-7.2,21.3-16.1,25l22.1,33.1h-26.3l-23.2-36.4v36.4h-22.4V230h27.9
	C382.1,230,395.7,239.7,395.7,258.8L395.7,258.8z M252.3,249c-13.9,0-23.6,11.1-23.6,24.4s9.6,24.4,23.6,24.4
	c9.5,0,16.6-5.1,21.1-12.1l17.2,11.3c-7.9,13-21.9,21.4-38.8,21.4c-26.3,0-45.6-19.6-45.6-45s19.3-44.9,45.6-44.9
	c17,0,31.1,8,39.1,21.3l-17.7,11.6C268.5,253.9,261.8,249,252.3,249z M446.2,249c-13.9,0-23.6,11.1-23.6,24.4s9.6,24.4,23.6,24.4
	c9.5,0,16.6-5.1,21.1-12.1l17.2,11.3c-7.9,13-21.9,21.4-38.8,21.4c-26.3,0-45.6-19.6-45.6-45s19.4-44.9,45.6-44.9
	c17,0,31.1,8,39.1,21.3l-17.7,11.6C462.4,253.9,455.7,249,446.2,249L446.2,249z"
      />
      <path
        class="st1"
        d="M904.1,425.2c-6.9,6.9-18.1,6.9-25,0s-6.9-18.1,0-25s18.1-6.9,25,0c3.3,3.3,5.2,7.8,5.2,12.5
	C909.3,417.4,907.5,421.9,904.1,425.2z M880.8,401.9c-2.9,2.8-4.5,6.7-4.5,10.8c-0.1,4.1,1.5,8,4.4,10.8c5.9,5.9,15.5,6,21.4,0.1
	c0,0,0,0,0.1-0.1c2.9-2.9,4.5-6.8,4.4-10.8c0-8.4-6.7-15.2-15.1-15.2C887.6,397.5,883.7,399.1,880.8,401.9L880.8,401.9z
	 M891.2,402.9c1.8-0.1,3.6,0.1,5.3,0.7c2,0.8,3,2.5,3,4.9c0.1,1.5-0.6,3-1.9,3.8c-0.9,0.5-1.8,0.8-2.8,0.9c1.3,0.1,2.5,0.8,3.3,1.9
	c0.6,0.9,1,1.9,1,3v1.4c0,0.5,0,0.9,0,1.5c0,0.3,0.1,0.7,0.2,1l0.1,0.2h-3.1c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2l-0.1-0.6v-1.5
	c0-2.2-0.6-3.7-1.8-4.4c-1.2-0.5-2.5-0.7-3.8-0.6h-2.7v7.6h-3.4v-19.4L891.2,402.9z M894.9,406c-1.3-0.6-2.7-0.8-4.1-0.7h-2.9v7h3.1
	c1.1,0.1,2.2-0.1,3.2-0.4c1.7-0.8,2.5-2.9,1.7-4.6C895.7,406.7,895.3,406.3,894.9,406L894.9,406z"
      />
    </StyledLogo>
  )
}

export default LogoLight
