import React from 'react'
import styled from 'styled-components'

const StyledBurger = styled.div`
  z-index: 10;
  display: none;

  @media (max-width: ${({ theme }) => theme.desktop}) {
    display: block;
  }

  .hamburger {
    padding: 5px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }

  .hamburger:hover {
    opacity: 0.7;
  }

  .hamburger.is-active:hover {
    opacity: 0.7;
  }

  .hamburger-box {
    width: 30px;
    height: 12px;
    display: inline-block;
    position: relative;
    transition: ${({ open }) =>
      open
        ? 'bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)'
        : 'bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 30px;
    height: 2px;
    background-color: ${({ theme, open }) => (open ? theme.subprimary : theme.primary)};
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }

  .hamburger-inner::before {
    top: -10px;
  }

  .hamburger-inner::after {
    bottom: -10px;
  }

  .hamburger--squeeze .hamburger-inner {
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0deg)')};
    transition-delay: ${({ open }) => (open ? '0.12s' : '0s')};
    transition-duration: 0.075s;
    transition-timing-function: ${({ open }) =>
      open ? 'cubic-bezier(0.215, 0.61, 0.355, 1)' : 'cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
  }

  .hamburger--squeeze .hamburger-inner::before {
    transition: ${({ open }) =>
      open ? 'top 0.075s ease, opacity 0.075s 0.12s ease' : 'top 0.075s 0.12s ease, opacity 0.075s ease'};

    top: ${({ open }) => (open ? '0' : '0s')};
    opacity: ${({ open }) => (open ? '0' : '1')};
  }

  .hamburger--squeeze .hamburger-inner::after {
    transition: ${({ open }) =>
      open
        ? 'bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)'
        : 'bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)'};

    bottom: ${({ open }) => (open ? '0' : '0s')};
    transform: ${({ open }) => (open ? 'rotate(-90deg)' : 'auto')};
  }
`

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div className="hamburger hamburger--squeeze">
        <div className="hamburger-box">
          <div className="hamburger-inner" />
        </div>
      </div>
    </StyledBurger>
  )
}

export default Burger
